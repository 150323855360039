import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { SvgIconComponent, SvgIcons } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterService } from '@suite/router';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';

// TODO: Cross app imports like this should be avoided. This creates a lot of noise in the nx graph.
import { AccessService, CustomerService } from '../../../../../../apps/suite/src/app/shared/access';

interface NavLink {
  path: string;
  tooltip: string;
  icon: SvgIcons;
}

@Component({
  selector: 'lib-rail',
  imports: [
    CommonModule,
    DividerModule,
    ButtonModule,
    AvatarModule,
    TooltipModule,
    RouterLink,
    SvgIconComponent,
    TranslateModule,
  ],
  templateUrl: './rail.component.html',
  styleUrl: './rail.component.scss',
})
export class RailComponent implements OnInit {
  private readonly routerService = inject(RouterService);
  private readonly customerService = inject(CustomerService);
  private readonly access = inject(AccessService);
  private readonly storage = inject(ApplicationStorageService);
  private readonly destroyRef$ = inject(DestroyRef);

  isOnProfile = computed(() => this.routerService.currentUrl() === '/profile');

  currentCustomer = toSignal(this.customerService.selectedCustomer$);
  canSelectCustomer = signal(this.storage.getItem('user.hasMultipleCustomers') ?? false);
  selectCustomerTooltip = computed(() => {
    const currentCustomer = this.currentCustomer();
    if (!currentCustomer) {
      return 'Unknown customer';
    }

    return `${currentCustomer.customerID} - ${currentCustomer.name}`;
  });

  primaryNavLinks = signal<NavLink[]>([]);

  secondaryNavLinks = signal<NavLink[]>([]);

  ngOnInit(): void {
    // Build primary nav links based on Application `hasAccess` flag
    this.access
      .getApplicationList()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe((apps) => {
        this.primaryNavLinks.set([
          ...apps
            .filter((a) => a.hasAccess === true)
            .map((app) => ({
              path: app.applicationUrl,
              tooltip: app.applicationName,
              icon: this.getIcon(app.applicationID) as SvgIcons,
            })),
          {
            icon: 'clipboard',
            path: '/asset-log',
            tooltip: 'Asset log',
          },
        ]);
      });

    // Build secondary nav links based on Feature access
    this.access.isInitialized$.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe(() => {
      const links = [
        ...(this.access.hasFeature('configuration', 'R')
          ? [{ path: '/config', tooltip: 'Configuration', icon: 'cog' as SvgIcons }]
          : []),
        ...(this.access.hasFeature('acl-config', 'R')
          ? [
              {
                path: '/acl',
                tooltip: 'ACL',
                icon: 'acl' as SvgIcons,
              },
            ]
          : []),
        ...(this.access.hasFeature('user-management', 'R')
          ? [{ path: '/users', tooltip: 'User management', icon: 'users' as SvgIcons }]
          : []),
      ];
      this.secondaryNavLinks.set(links);
    });

    // Hide or display select customer link based on users customer list length
    this.access
      .getCustomerList()
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe((customers) => {
        this.storage.setItem('user.hasMultipleCustomers', customers.length > 1);
        this.canSelectCustomer.set(customers.length > 1);
      });
  }

  isActive(path: string): boolean {
    return this.routerService.currentUrl().startsWith(path.slice(0, -1)) ?? false;
  }

  private getIcon(applicationID: number) {
    // prettier-ignore
    switch (applicationID) {
      case 1:
        return 'energy';
      case 2:
        return 'bell';
      case 3:
        return 'chip';
      case 4:
        return 'flow';
      case 5:
        return 'money';
    }
    return '';
  }

  goBack() {
    window.history.back();
  }
}
